import { Grid as MuiGrid, List as MuiList, ListItem as MuiListItem, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['look', 'message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	/* ${({ message, position }) =>
		position === 'top' || !message ? `box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09);` : ''} */
`

const GridContainer = styled(MuiGrid)`
	padding: calc(var(--spacing-1) * 1.5) var(--spacing-2);
`

const LogoWrapper = styled('div')`
	max-width: 80px;
	width: 100%;
	margin: 0 auto;
	svg {
		width: 100%;
		height: auto;
	}
	h1 {
		font-size: 0;
		line-height: 0;
	}
`

const StackStyled = styled(MuiStack, { shouldForwardProp })`
	.mini-trigger {
		padding: 0;
	}
`

const ListStyled = styled(MuiList)`
	padding: var(--spacing-9) calc(var(--spacing-1) * 2.75) 0;
`

const ListItemStyled = styled(MuiListItem)`
	padding-bottom: calc(var(--spacing-1) * 1.25);
	padding-top: calc(var(--spacing-1) * 1.25);
`

const ButtonAction = styled(Button)`
	border-bottom: 0;
	.MuiButton-startIcon {
		margin-right: calc(var(--spacing-1) * 2.5);
	}
	.MuiButton-iconSizeMedium.MuiButton-startIcon .MuiSvgIcon-fontSizeSmall {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(19)};
	}
`

export { ButtonAction, GridContainer, Header, ListItemStyled, ListStyled, LogoWrapper, StackStyled }
