import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { useRouter } from 'next/router'
import Link from '../../molecules/link'
import { H1, LogoRoot } from './logo.styled'

const LogoSvg = ({ title, ...props }) => (
	<svg
		version="1.2"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 9"
		width="24"
		height="9"
		fill="currentColor"
		{...props}
	>
		{title && <title>{title}</title>}
		<path
			fillRule="evenodd"
			d="m5.8 1.3q0.9 1 0.9 2.3 0 1.3-0.9 2.3-0.9 0.9-2.2 0.9-1.3 0-2.2-1-0.8-0.9-0.8-2.2c0-0.6 0.1-1.1 0.4-1.6q0.4-0.8 1.1-1.2 0.7-0.4 1.5-0.4 1.3 0 2.2 0.9zm-0.2 2.3q0-0.9-0.6-1.4-0.6-0.6-1.4-0.6-0.8 0-1.3 0.5-0.6 0.6-0.6 1.5 0 1 0.7 1.6 0.5 0.5 1.2 0.4 0.8 0.1 1.4-0.5 0.6-0.7 0.6-1.5z"
		/>
		<path
			fillRule="evenodd"
			d="m12.6 2.7q0.6 0.6 0.6 1.7 0 1-0.7 1.6-0.6 0.7-1.4 0.7-0.4 0-0.7-0.1-0.3-0.1-0.6-0.5v0.5h-1.1v-6.2h1.1v2.2q0.3-0.3 0.6-0.4 0.4-0.2 0.7-0.2c0.6 0 1.1 0.2 1.5 0.7zm-0.5 1.7q0-0.6-0.3-1-0.4-0.4-0.9-0.4-0.5 0-0.8 0.4-0.4 0.4-0.4 1 0 0.6 0.4 0.9 0.3 0.4 0.8 0.4 0.5 0 0.9-0.4 0.3-0.4 0.3-0.9z"
		/>
		<path
			fillRule="evenodd"
			d="m17.1 2.1h1.1v4.5h-1.1v-0.5q-0.3 0.4-0.7 0.5-0.3 0.1-0.6 0.1-0.9 0-1.5-0.6-0.6-0.7-0.6-1.7 0-1 0.6-1.7 0.6-0.7 1.4-0.7 0.4 0 0.7 0.2 0.4 0.1 0.7 0.4zm0 2.2q0-0.5-0.3-0.9-0.4-0.4-0.9-0.4-0.5 0-0.8 0.4-0.3 0.4-0.3 0.9 0 0.6 0.3 1 0.3 0.4 0.8 0.4 0.5 0 0.9-0.4 0.3-0.3 0.3-1z"
		/>
		<path
			fillRule="evenodd"
			d="m23.4 2.1v3.8q0 1.2-0.4 1.7-0.6 0.7-1.8 0.7-0.7 0.1-1.1-0.1-0.4-0.2-0.7-0.5-0.3-0.3-0.5-0.8h1.2q0.2 0.2 0.4 0.3 0.3 0.1 0.6 0.1 0.5 0 0.7-0.1 0.3-0.2 0.4-0.4 0.1-0.2 0.1-0.8-0.3 0.3-0.6 0.5-0.3 0.1-0.7 0.1-0.9 0-1.5-0.7-0.6-0.6-0.6-1.6 0-1.1 0.7-1.7 0.6-0.6 1.4-0.6 0.3 0 0.7 0.1 0.3 0.2 0.6 0.5v-0.5zm-1 2.2q0-0.6-0.3-0.9-0.3-0.4-0.9-0.4-0.5 0-0.8 0.4-0.4 0.4-0.4 0.9 0 0.6 0.4 0.9 0.3 0.4 0.8 0.4 0.5 0 0.9-0.4 0.3-0.3 0.3-0.9z"
		/>
	</svg>
)

const Logo = ({ originalWidth, originalHeight, className, ...props }) => {
	const { siteName: siteName } = useConfig()

	const router = useRouter()

	return (
		<LogoRoot className={cx('Logo-root', className)}>
			{router?.asPath.split('?')[0] === PAGE_PATH_HOME ? (
				<H1>
					<LogoSvg width={originalWidth} height={originalHeight} {...props} alt={siteName} />
				</H1>
			) : (
				<Link href={PAGE_PATH_HOME}>
					<LogoSvg width={originalWidth} height={originalHeight} {...props} alt={siteName} />
				</Link>
			)}
		</LogoRoot>
	)
}

export default Logo
